module services {
    export module costing {

        export class costParameterProductCostGroupService implements interfaces.costing.ICostParameterProductCostGroupService {

            static $inject = ["$resource", "ENV"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }

            getProductCostGroupList(): ng.resource.IResourceClass<interfaces.costing.ICostProductCostGroup> {
                return this.$resource<interfaces.costing.ICostProductCostGroup>(this.ENV.DSP_URL + "CostParameterProductCostGroup/GetProductCostGroupList", {
                    showAllActive: '@showAllActive',
                    entityId: '@entityId',
                    productCostGroupId: '@productCostGroupId'
                }, {
                        query: {
                            method: 'GET',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates },
                        }
                    });
            }

            saveProductCostGroup(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "CostParameterProductCostGroup/SaveProductCostGroup", {
                });
            }

            removeProductCostGroup(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "CostParameterProductCostGroup/RemoveProductCostGroup", {
                    cpgId: '@cpgId',
                });
            }


        }

    }

    angular.module("app").service("costParameterProductCostGroupService", services.costing.costParameterProductCostGroupService);
}